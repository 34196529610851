<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.53 1.152C12.2057 0.949333 11.7943 0.949333 11.47 1.152L3.47 6.152C3.0917 6.38844 2.91577 6.84686 3.03875 7.27568C3.16174 7.7045 3.55389 8 4 8H20C20.4461 8 20.8383 7.7045 20.9612 7.27568C21.0842 6.84686 20.9083 6.38844 20.53 6.152L12.53 1.152ZM12 3.17925L16.5132 6H7.4868L12 3.17925Z"
    />
    <path
      d="M7 11C7 10.4477 6.55228 10 6 10C5.44772 10 5 10.4477 5 11V18C5 18.5523 5.44772 19 6 19C6.55228 19 7 18.5523 7 18V11Z"
    />
    <path
      d="M3 21C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H21C21.5523 23 22 22.5523 22 22C22 21.4477 21.5523 21 21 21H3Z"
    />
    <path
      d="M10 10C10.5523 10 11 10.4477 11 11V18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18V11C9 10.4477 9.44772 10 10 10Z"
    />
    <path
      d="M15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11V18C13 18.5523 13.4477 19 14 19C14.5523 19 15 18.5523 15 18V11Z"
    />
    <path
      d="M18 10C18.5523 10 19 10.4477 19 11V18C19 18.5523 18.5523 19 18 19C17.4477 19 17 18.5523 17 18V11C17 10.4477 17.4477 10 18 10Z"
    />
  </svg>
</template>
